/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    div: "div"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Para instalar el USB wifi RTL8187B, vamos a necesitar un kernel igual o superior al 2.6.27, para instalarlo vamos a usar el repositorio backports de Debian, que es un repositorio no oficial donde esta todo lo que necesitamos y no está en el oficial de Debian."), "\n", React.createElement(_components.p, null, "Para esto añadimos al sources.list de apt (/etc/apt/sources.list) la siguiente linea:"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre class=\"language-bash\"><code class=\"language-bash\">deb http://www.backports.org/debian/ lenny-backports main contrib non-free</code></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "Luego hacemos un aptitude update y instalamos el kernel, los wireless-tools y los firmwares que me hacian falta para la tarjeta ethernet."), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre class=\"language-bash\"><code class=\"language-bash\"><span class=\"token function\">aptitude</span> <span class=\"token parameter variable\">-t</span> lenny-backports <span class=\"token function\">install</span> linux-image-2.6.30-bpo.1-<span class=\"token variable\"><span class=\"token variable\">$(</span><span class=\"token function\">uname</span> <span class=\"token parameter variable\">-r</span> <span class=\"token operator\">|</span> <span class=\"token function\">sed</span> <span class=\"token string\">'s,.\\*-,,g'</span><span class=\"token variable\">)</span></span> wireless-tools firmware-linux</code></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "Ahora instalamos el modulo del kernel de rtl8187"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre class=\"language-bash\"><code class=\"language-bash\">modprobe rtl8187</code></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "Y ya debería detectarnos el dispositivo de wifi."), "\n", React.createElement(_components.p, null, "Lo siguiente es instalar Wicd para conectarnos a la red que queramos. Wicd puede que sea el mejor gestor de red de linux y para un escritorio XFCE funciona perfectamente."), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"text\"><pre class=\"language-text\"><code class=\"language-text\">aptitude install wicd</code></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "Recordad que está en el repositorio backports también así que no lo desactivéis."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
