import React, { useEffect, useState } from "react";
import { FaHeart, FaRetweet, FaReply, FaComment } from "react-icons/fa";

const Webmentions = ({postUrl}) => {
  const [webmentions, setWebmentions] = useState([]);
  const [likes, setLikes] = useState(0);
  const [reposts, setReposts] = useState(0);
  const [replies, setReplies] = useState(0);
  const [mentions, setMentions] = useState(0);

  useEffect(() => {
    fetch(`https://webmention.io/api/mentions.jf2?target=${postUrl}&sort-by=published&sort-dir=up`)
      .then(response => response.json())
      .then(responseJson => {
        const mentions = responseJson.children || [];
        const likeCount = mentions.filter(mention => mention["wm-property"] === "like-of").length;
        const repostCount = mentions.filter(mention => mention["wm-property"] === "repost-of").length;
        const replyCount = mentions.filter(mention => mention["wm-property"] === "reply-of").length;
        const mentionCount = mentions.filter(mention => mention["wm-property"] === "mention-of").length; 
        const contentMentions = mentions.filter(mention => mention.content !== undefined);
        setWebmentions(contentMentions);
        setLikes(likeCount);
        setReposts(repostCount);
        setReplies(replyCount);
        setMentions(mentionCount);
      });
  }, [postUrl]);

  const createSummary = (text, wordLimit) => {
    const words = text.split(" ");
    if (words.length <= wordLimit) {
      return text;
    }
    return words.slice(0, wordLimit).join(" ") + "...";
  };

  return (
    <section className='webmentions'>
      <h2>Webmentions</h2>
      <ul className="fa-mentions">
        <li><FaHeart />{likes}</li>
        <li><FaRetweet />{reposts}</li>
        <li><FaReply />{replies}</li>
        <li><FaComment />{mentions}</li>
      </ul>
      <ul className="comments-list">
        {webmentions.map(mention => (
          <li key={mention["wm-id"]}>
            <section className="comments">
              <img className="bio-avatar" src={mention.author.photo} alt={mention.author.name} />
              <div>
              <p>
              <span className="bio-name">{mention.author.name}</span> - {new Date(mention.published).toLocaleDateString()}
              </p>
              <q>{createSummary(mention.content.text, 20)}</q>
              <p>
                Seguir leyendo en el post <a href={mention.url}>{mention.name}</a>.
              </p>
              </div>
            </section>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default Webmentions

