/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import bioImage from "../images/profile-pic.png"
import { FaMastodon, FaGithub } from "react-icons/fa";

const Bio = ({ className }) => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          author {
            name
            summary
          }
          mastodon {
            server
            user
          }
          github
          siteUrl
        }
      }
    }
  `)

  // Set these values by editing "siteMetadata" in gatsby-config.js
  const author = data.site.siteMetadata?.author
  const mastodon = data.site.siteMetadata?.mastodon
  const mastodonLink = `${mastodon?.server || ``}/${mastodon?.user || ``}`
  const githubLink = data.site.siteMetadata?.github

  return (
    <section className={`bio h-card ${className || ''}`}>
      <a href={data.site.siteMetadata?.siteUrl} className="u-url u-uid">
        <img
          className="bio-avatar u-photo"
          src={bioImage}
          alt="Profile picture"
        />
      </a>
      {author?.name && (
        <div>
          <p className="bio-name p-name">{author.name}</p>
          <p className="p-note">
          {author?.summary || null}
            {` `}
          </p>
          <ul className="fa-badges">
            <li>
              <a rel="me" aria-label="Perfil de Mastodon" href={`${mastodon?.server}/${mastodon?.user || ``}`} className="u-url">
                <FaMastodon />
              </a>
            </li>
            <li>
              <a rel="me" aria-label="Perfil de Github" href={githubLink} className="u-url">
                <FaGithub />
              </a>
            </li>
          </ul>
        </div>
      )}
    </section>
  )
}

export default Bio
